<template>
  <div class="d-flex justify-center align-center constainer-not-found">
    <div class="row">
      <h1>404 - </h1>
    </div>
    <hr />
    <div class="row">
      <h1>Página no encontrada</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
html {
  overflow: hidden;
}
.constainer-not-found {
  width: 100vw;
  height: 90vh;
  overflow-y: hidden;
}
</style>
